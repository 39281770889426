import React from 'react';

import { Spacing, Container } from '@matchbox-mobile/kenra-storybook';

export default function PlainPage({ content }) {
  return (
    <>
      <Spacing removeSpaceBottom>
        <Container dangerouslySetInnerHTML={{ __html: content }} />
      </Spacing>
    </>
  );
}
